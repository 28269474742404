import type { CheckFieldSpec } from '@aurora/shared-client/components/form/CheckField/CheckField';
import {
  FormCheckInputType,
  FormFieldVariant,
  FormInputFieldInputType
} from '@aurora/shared-client/components/form/enums';
import type { IconRadioSpec } from '@aurora/shared-client/components/form/IconRadioField/IconRadioField';
import type { InputFieldSpec } from '@aurora/shared-client/components/form/InputField/InputField';
import type {
  MultiCheckFieldOption,
  MultiCheckFieldSpec
} from '@aurora/shared-client/components/form/MultiCheckField/MultiCheckField';
import type { PillRadioSpec } from '@aurora/shared-client/components/form/PillRadioField/PillRadioField';
import type { PlaceholderFormFieldSpec } from '@aurora/shared-client/components/form/PlaceholderFormField/PlaceholderFormField';
import type { RangeFieldSpec } from '@aurora/shared-client/components/form/RangeField/RangeField';
import type { SelectFieldSpec } from '@aurora/shared-client/components/form/SelectField/SelectField';
import Icons from '@aurora/shared-client/icons';
import { NodeCardSize } from '@aurora/shared-types/nodes/enums';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import { TextAlignment } from '@aurora/shared-types/texts/enums';
import type React from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import type { ConfigurationSpec, InstanceScopedWidgetProps } from '../../../common/Widget/types';
import type {
  CardLayoutOptions,
  CardLayoutProps,
  ConfigurationFormData,
  FeaturedPlacesWidgetConfigurableProps,
  FeaturedPlacesWidgetProps,
  LayoutOptions,
  LayoutProps,
  ListLayoutOptions,
  ListLayoutProps
} from '../FeaturedPlacesWidget/types';
import {
  FeaturedPlacesWidgetLayout,
  FeaturedPlacesWidgetListStyle,
  LeadWithOption
} from '../FeaturedPlacesWidget/types';
import formSchema from './FeaturedPlacesWidgetEditor.form.json';
import { nodeCardAlignmentOptions } from '../../../../helpers/nodes/NodeCardHelper/NodeCardHelper';

/**
 * User defined type-guard for working with props specific to the List layout
 *
 * @param layoutProps the layout props
 */
export function isLayoutPropsListLayout(layoutProps: LayoutProps): layoutProps is ListLayoutProps {
  return layoutProps?.layout === FeaturedPlacesWidgetLayout.LIST;
}

/**
 * User defined type-guard for working with props specific to the Card layout
 *
 * @param layoutProps the layout props
 */
export function isLayoutPropsCardLayout(layoutProps: LayoutProps): layoutProps is CardLayoutProps {
  return layoutProps?.layout === FeaturedPlacesWidgetLayout.CARD;
}

export const defaultListLayoutProps: ListLayoutProps = {
  layout: FeaturedPlacesWidgetLayout.LIST,
  layoutOptions: {
    useNodeDescription: true,
    useNodeTopicsCount: false,
    useUnreadMessagesCount: false,
    useNodeLatestActivityTime: false,
    useLockIcon: true
  },
  listStyle: FeaturedPlacesWidgetListStyle.DIVIDE,
  leadWithOption: LeadWithOption.AVATAR,
  descriptionClampLines: 1,
  nodeDescendantsPageSize: 0
};

export const defaultCardLayoutProps: CardLayoutProps = {
  layout: FeaturedPlacesWidgetLayout.CARD,
  layoutOptions: {
    useNodeAvatar: true,
    useNodeDescription: true,
    useNodeLatestActivityTime: true,
    useNodeTopicsCount: true,
    useUnreadMessagesCount: false,
    useChildNodes: false
  },
  cardSize: NodeCardSize.LG,
  textAlignment: TextAlignment.CENTER,
  descriptionClampLines: 2
};

/**
 * Merges the current options for the previously selected layout with the newly selected layout's default options,
 * provided that they share any options
 *
 * @param existingData the existing layout options
 * @param selectedLayoutDefaultProps the default props for the selected layout
 */
export function mergeExisting<LayoutOptionT extends LayoutProps>(
  existingData: {
    listStyle: FeaturedPlacesWidgetListStyle;
    layoutOptions: LayoutOptions;
  },
  selectedLayoutDefaultProps: LayoutOptionT
): LayoutOptionT {
  const targetProps: LayoutOptionT = { ...selectedLayoutDefaultProps };
  const { listStyle, layoutOptions } = existingData;
  if (!isLayoutPropsCardLayout(targetProps) && typeof listStyle === 'string') {
    targetProps.listStyle = listStyle;
  }
  // preserve layout option selections if possible
  Object.entries(layoutOptions).forEach(([key, value]) => {
    if (key in targetProps.layoutOptions) {
      targetProps.layoutOptions[key] = value;
    }
  });

  return targetProps;
}

/**
 * Maps the List layout option values to the MultiCheckFieldSpec
 */
export function getListLayoutMultiCheckFieldOptions(): Array<
  MultiCheckFieldOption<keyof ListLayoutOptions, 'listLayoutOptions', ConfigurationFormData>
> {
  return Object.keys(defaultListLayoutProps.layoutOptions).map((key: keyof ListLayoutOptions) => {
    return { name: key };
  });
}

/**
 * Maps the Card layout option values to the MultiCheckFieldSpec
 */
export function getCardLayoutMultiCheckFieldOptions(): Array<
  MultiCheckFieldOption<keyof CardLayoutOptions, 'cardLayoutOptions', ConfigurationFormData>
> {
  return Object.keys(defaultCardLayoutProps.layoutOptions).map((key: keyof CardLayoutOptions) => {
    return { name: key };
  });
}

/**
 * Takes the current form data transforms it for use by the widget props
 *
 * @param data the form data
 * @param layout the current FeaturedPlacesWidget layout from props
 * @param instanceId the FeaturedPlacesWidget instance id
 */
export function transformToWidgetProps(
  data: ConfigurationFormData,
  layout: FeaturedPlacesWidgetLayout,
  instanceId: string
): FeaturedPlacesWidgetProps {
  const {
    layout: selectedLayout,
    listLayoutOptions,
    cardLayoutOptions,
    listStyle,
    cardSize,
    cardTextAlignment,
    leadWithOption,
    descriptionClampLines,
    nodeDescendantsPageSize,
    moreOptions
  } = data;
  const commonProps: Omit<FeaturedPlacesWidgetProps, 'layoutProps'> = {
    showPager: moreOptions.showPager,
    lazyLoad: moreOptions.lazyLoad,
    titleSrOnly: data.titleSrOnly,
    pageSize: data.pageSize,
    instanceId
  };

  const isNewLayoutSelected: boolean = selectedLayout !== layout;
  if (selectedLayout === FeaturedPlacesWidgetLayout.LIST) {
    if (!isNewLayoutSelected) {
      const newLayoutProps: ListLayoutProps = {
        layout: selectedLayout,
        layoutOptions: listLayoutOptions,
        listStyle,
        leadWithOption,
        descriptionClampLines,
        nodeDescendantsPageSize
      };

      return { ...commonProps, layoutProps: newLayoutProps };
    } else {
      const newLayoutProps: ListLayoutProps = {
        ...mergeExisting<ListLayoutProps>(
          {
            layoutOptions: cardLayoutOptions,
            listStyle
          },
          defaultListLayoutProps
        )
      };

      return { layoutProps: newLayoutProps, ...commonProps };
    }
  } else {
    if (!isNewLayoutSelected) {
      const newLayoutProps: CardLayoutProps = {
        layout: selectedLayout,
        layoutOptions: cardLayoutOptions,
        cardSize,
        textAlignment: cardTextAlignment,
        descriptionClampLines
      };
      return { layoutProps: newLayoutProps, ...commonProps };
    } else {
      const newLayoutProps: CardLayoutProps = {
        ...mergeExisting<CardLayoutProps>(
          {
            layoutOptions: listLayoutOptions,
            listStyle
          },
          defaultCardLayoutProps
        )
      };

      return { layoutProps: newLayoutProps, ...commonProps };
    }
  }
}

/**
 * Returns the configuration spec for the FeaturedPlacesWidget
 *
 * @param instanceId the instance id for the widget
 * @param configurableProps configurable props for the widget
 * @param titlePlaceholder the placeholder for the title field
 * @param editContentButton the edit content button
 */
export default function useFeaturedPlacesWidgetConfiguration(
  instanceId: string,
  configurableProps: FeaturedPlacesWidgetConfigurableProps,
  titlePlaceholder: string,
  editContentButton: React.ReactElement
): ConfigurationSpec<InstanceScopedWidgetProps, ConfigurationFormData> {
  const cx = useClassNameMapper();

  const { layoutProps, title, titleSrOnly, pageSize, showPager, lazyLoad } = configurableProps;
  const { layout } = layoutProps;

  /* Layout FieldGroup */
  const layoutField: IconRadioSpec<'layout', ConfigurationFormData> = {
    name: 'layout',
    fieldVariant: FormFieldVariant.ICON_RADIO,
    buttonsPerColumn: 2,
    values: [
      {
        key: FeaturedPlacesWidgetLayout.LIST,
        value: FeaturedPlacesWidgetLayout.LIST,
        icon: Icons.ContentLayoutListIcon
      },
      {
        key: FeaturedPlacesWidgetLayout.CARD,
        value: FeaturedPlacesWidgetLayout.CARD,
        icon: Icons.ContentLayoutCardIcon
      }
    ],
    defaultValue: layout
  };

  /* List Style FieldGroup */
  const listStyleField: PillRadioSpec<'listStyle', ConfigurationFormData> = {
    name: 'listStyle',
    fieldVariant: FormFieldVariant.PILL_RADIO,
    isVisible: {
      watchFields: ['layout'],
      callback: ({ layout: selectedLayout }: ConfigurationFormData) =>
        selectedLayout !== FeaturedPlacesWidgetLayout.CARD
    },
    values: [
      {
        key: FeaturedPlacesWidgetListStyle.SPACE,
        value: FeaturedPlacesWidgetListStyle.SPACE
      },
      {
        key: FeaturedPlacesWidgetListStyle.DIVIDE,
        value: FeaturedPlacesWidgetListStyle.DIVIDE
      },
      {
        key: FeaturedPlacesWidgetListStyle.BORDER,
        value: FeaturedPlacesWidgetListStyle.BORDER
      }
    ],
    defaultValue: !isLayoutPropsCardLayout(layoutProps)
      ? layoutProps.listStyle
      : defaultListLayoutProps.listStyle
  };

  const cardSizeField: PillRadioSpec<'cardSize', ConfigurationFormData> = {
    name: 'cardSize',
    fieldVariant: FormFieldVariant.PILL_RADIO,
    isVisible: {
      watchFields: ['layout'],
      callback: ({ layout: selectedLayout }: ConfigurationFormData) =>
        selectedLayout === FeaturedPlacesWidgetLayout.CARD
    },
    values: [
      {
        key: 'small',
        value: NodeCardSize.XS
      },
      {
        key: 'medium',
        value: NodeCardSize.SM
      },
      {
        key: 'large',
        value: NodeCardSize.LG
      }
    ],
    defaultValue: isLayoutPropsCardLayout(layoutProps)
      ? layoutProps.cardSize
      : defaultCardLayoutProps.cardSize
  };

  const cardTextAlignmentField: PillRadioSpec<'cardTextAlignment', ConfigurationFormData> = {
    name: 'cardTextAlignment',
    fieldVariant: FormFieldVariant.PILL_RADIO,
    isVisible: {
      watchFields: ['layout'],
      callback: ({ layout: selectedLayout }: ConfigurationFormData) =>
        selectedLayout === FeaturedPlacesWidgetLayout.CARD
    },
    defaultValue: isLayoutPropsCardLayout(layoutProps)
      ? layoutProps.textAlignment
      : defaultCardLayoutProps.textAlignment,
    values: nodeCardAlignmentOptions()
  };

  /* Title FieldGroup */
  const titleField: InputFieldSpec<'title', ConfigurationFormData> = {
    name: 'title',
    fieldVariant: FormFieldVariant.INPUT,
    inputType: FormInputFieldInputType.TEXT,
    defaultValue: title ?? '',
    attributes: {
      placeholder: titlePlaceholder
    }
  };

  const titleSrOnlyField: CheckFieldSpec<'titleSrOnly', ConfigurationFormData> = {
    name: 'titleSrOnly',
    fieldVariant: FormFieldVariant.CHECK,
    inputType: FormCheckInputType.SWITCH,
    defaultValue: titleSrOnly
  };

  /* Edit Content FieldGroup */
  const editContentField: PlaceholderFormFieldSpec<'editContent', ConfigurationFormData> = {
    name: 'editContent',
    fieldVariant: FormFieldVariant.PLACEHOLDER_FORM_FIELD,
    Content: () => editContentButton,
    defaultValue: null,
    formGroupSpec: {
      label: false
    }
  };

  /* Page Size FieldGroup */
  const pageSizeField: RangeFieldSpec<'pageSize', ConfigurationFormData> = {
    name: 'pageSize',
    fieldVariant: FormFieldVariant.RANGE,
    showOutput: true,
    defaultValue: pageSize,
    min: 1,
    max: 20
  };

  const nodeDescendantsPageSizeField: RangeFieldSpec<
    'nodeDescendantsPageSize',
    ConfigurationFormData
  > = {
    name: 'nodeDescendantsPageSize',
    fieldVariant: FormFieldVariant.RANGE,
    showOutput: true,
    defaultValue: isLayoutPropsListLayout(layoutProps)
      ? layoutProps.nodeDescendantsPageSize
      : defaultListLayoutProps.nodeDescendantsPageSize,
    min: 0,
    max: 10,
    isVisible: {
      watchFields: ['layout'],
      callback: ({ layout: selectedLayout }: ConfigurationFormData) => {
        return selectedLayout === FeaturedPlacesWidgetLayout.LIST;
      }
    }
  };

  /* Layout Options FieldGroup */
  const listLayoutListItemsField: MultiCheckFieldSpec<'listLayoutOptions', ConfigurationFormData> =
    {
      name: 'listLayoutOptions',
      fieldVariant: FormFieldVariant.MULTI_CHECK_BOX,
      inputType: FormCheckInputType.SWITCH,
      options: getListLayoutMultiCheckFieldOptions(),
      defaultValue: isLayoutPropsListLayout(layoutProps)
        ? layoutProps.layoutOptions
        : defaultListLayoutProps.layoutOptions,
      isVisible: {
        watchFields: ['layout'],
        callback: ({ layout: selectedLayout }: ConfigurationFormData) => {
          return selectedLayout === FeaturedPlacesWidgetLayout.LIST;
        }
      }
    };

  const cardLayoutItemsField: MultiCheckFieldSpec<'cardLayoutOptions', ConfigurationFormData> = {
    name: 'cardLayoutOptions',
    fieldVariant: FormFieldVariant.MULTI_CHECK_BOX,
    inputType: FormCheckInputType.SWITCH,
    options: getCardLayoutMultiCheckFieldOptions(),
    defaultValue: isLayoutPropsCardLayout(layoutProps)
      ? layoutProps.layoutOptions
      : defaultCardLayoutProps.layoutOptions,
    isVisible: {
      watchFields: ['layout'],
      callback: ({ layout: selectedLayout }: ConfigurationFormData) => {
        return selectedLayout === FeaturedPlacesWidgetLayout.CARD;
      }
    }
  };

  const leadWithOptionField: SelectFieldSpec<'leadWithOption', ConfigurationFormData> = {
    name: 'leadWithOption',
    fieldVariant: FormFieldVariant.SELECT,
    defaultValue: isLayoutPropsListLayout(layoutProps)
      ? layoutProps.leadWithOption
      : defaultListLayoutProps.leadWithOption,
    isVisible: {
      watchFields: ['layout'],
      callback: ({ layout: selectedLayout }: ConfigurationFormData) => {
        return selectedLayout === FeaturedPlacesWidgetLayout.LIST;
      }
    },
    values: [
      {
        key: LeadWithOption.ICON,
        value: LeadWithOption.ICON
      },
      {
        key: LeadWithOption.AVATAR,
        value: LeadWithOption.AVATAR
      },
      {
        key: LeadWithOption.NONE,
        value: LeadWithOption.NONE
      }
    ]
  };

  const descriptionClampLinesField: RangeFieldSpec<'descriptionClampLines', ConfigurationFormData> =
    {
      name: 'descriptionClampLines',
      fieldVariant: FormFieldVariant.RANGE,
      showOutput: true,
      defaultValue:
        layoutProps.descriptionClampLines ||
        (isLayoutPropsListLayout(layoutProps)
          ? defaultListLayoutProps.descriptionClampLines
          : defaultCardLayoutProps.descriptionClampLines),
      min: 1,
      max: isLayoutPropsListLayout(layoutProps) ? 3 : 4
    };

  /* More Options Field Group */
  const moreOptionsField: MultiCheckFieldSpec<'moreOptions', ConfigurationFormData> = {
    name: 'moreOptions',
    fieldVariant: FormFieldVariant.MULTI_CHECK_BOX,
    inputType: FormCheckInputType.SWITCH,
    defaultValue: {
      showPager,
      lazyLoad
    },
    options: [
      {
        name: 'showPager'
      },
      {
        name: 'lazyLoad'
      }
    ]
  };

  return {
    instanceId,
    componentId: EndUserComponent.FEATURED_PLACES_WIDGET,
    fieldSpecs: [
      layoutField,
      listStyleField,
      cardSizeField,
      cardTextAlignmentField,
      titleField,
      titleSrOnlyField,
      editContentField,
      pageSizeField,
      listLayoutListItemsField,
      cardLayoutItemsField,
      leadWithOptionField,
      descriptionClampLinesField,
      nodeDescendantsPageSizeField,
      moreOptionsField
    ],
    formSchema: { cx, schema: formSchema },
    id: 'FeaturedPlacesWidgetEditor',
    submitOnChange: {
      watchFields: ['title']
    },
    textOverrideSpecs: [
      {
        fieldName: 'title',
        textKey: 'title'
      }
    ],
    transformToWidgetProps: (data: ConfigurationFormData) =>
      transformToWidgetProps(data, layout, instanceId)
  };
}
