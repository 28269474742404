import type { ApolloError } from '@apollo/client';
import type { QueryResult } from '@apollo/client/react';
import Button from '@aurora/shared-client/components/common/Button/Button';
import { ButtonVariant } from '@aurora/shared-client/components/common/Button/enums';
import { IconColor, IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import Icon from '@aurora/shared-client/components/common/Icon/Icon';
import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import QuiltContext from '@aurora/shared-client/components/context/QuiltContext';
import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import useNodePolicies from '@aurora/shared-client/components/nodes/useNodePolicies';
import { canUpdateFeaturedWidget } from '@aurora/shared-client/helpers/nodes/NodePolicyHelper';
import Icons from '@aurora/shared-client/icons';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import type {
  NodePoliciesQuery,
  QuiltFragment
} from '@aurora/shared-generated/types/graphql-types';
import { EndUserComponent, EndUserPages } from '@aurora/shared-types/pages/enums';
import {
  merge,
  UndefinedValueMergeBehavior
} from '@aurora/shared-utils/helpers/objects/ObjectHelper';
import { getLog } from '@aurora/shared-utils/log';
import dynamic from 'next/dynamic';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import type {
  FeaturedPlacesWidgetQuery,
  FeaturedPlacesWidgetQueryVariables
} from '../../../../types/graphql-types';
import EditableWidget from '../../../common/Widget/EditableWidget';
import type {
  InstanceScopedWidgetFC,
  InstanceScopedWidgetProps
} from '../../../common/Widget/types';
import type { EditContextInterface } from '../../../context/EditContext/EditContext';
import EditContext from '../../../context/EditContext/EditContext';
import useTranslation from '../../../useTranslation';
import FeaturedPlacesList from '../FeaturedPlacesList/FeaturedPlacesList';
import {
  defaultListLayoutProps,
  isLayoutPropsListLayout
} from '../FeaturedPlacesWidgetEditor/useFeaturedPlacesWidgetConfiguration';
import localStyles from './FeaturedPlacesWidget.module.pcss';
import type { FeaturedPlacesWidgetProps, LayoutProps } from './types';

const log = getLog(module);

const AddFeaturedPlacesModal = dynamic(
  () => import('../AddFeaturedPlacesModal/AddFeaturedPlacesModal')
);

/**
 * Returns the query variables for the FeaturedPlacesWidget query, optionally including node properties based off
 * of selected layout props
 *
 * @param instanceId the widget instance id
 * @param first the page size
 * @param quiltId the id of the quilt
 * @param coreNodeId the id of the core node
 * @param layoutProps the FeaturedPlacesWidget Layout props
 */
export function getFeaturedPlacesWidgetQueryVariables(
  instanceId: string,
  first: number,
  quiltId: string,
  coreNodeId: string,
  layoutProps: LayoutProps
): FeaturedPlacesWidgetQueryVariables {
  const {
    layoutOptions: {
      useNodeLatestActivityTime,
      useNodeDescription,
      useNodeTopicsCount,
      useUnreadMessagesCount
    }
  } = layoutProps;

  // common variables used by all FeaturedPlacesWidget Layout types
  const commonVariables: FeaturedPlacesWidgetQueryVariables = {
    instanceId,
    first,
    quiltId,
    coreNodeId,
    useNodeLatestActivityTime,
    useNodeDescription,
    useNodeTopicsCount,
    useNodeUnreadCount: useUnreadMessagesCount
  };

  if (isLayoutPropsListLayout(layoutProps)) {
    const {
      layoutOptions: { useLockIcon },
      nodeDescendantsPageSize
    } = layoutProps;
    return {
      ...commonVariables,
      useNodeUnreadCount: useUnreadMessagesCount,
      useMembershipType: useLockIcon,
      useChildNodes: nodeDescendantsPageSize > 0,
      childrenFirst: nodeDescendantsPageSize
    };
  } else {
    const { useNodeAvatar, useChildNodes } = layoutProps.layoutOptions;
    return {
      ...commonVariables,
      useNodeAvatar,
      useChildNodes
    };
  }
}

/**
 * Determines whether the FeaturedPlacesWidget is visible based off of the current page and node policies
 *
 * @param isPageEditorPage whether the current page is the PageEditorPage
 * @param nodePoliciesQuery the result of the nodePoliciesQuery
 * @param hasPlaces whether the widget contains places
 */
export function isFeaturedPlacesWidgetVisible(
  isPageEditorPage: boolean,
  nodePoliciesQuery: NodePoliciesQuery,
  hasPlaces: boolean
): boolean {
  if (nodePoliciesQuery) {
    if (isPageEditorPage || hasPlaces) {
      return true;
    } else {
      const { coreNode } = nodePoliciesQuery;

      return canUpdateFeaturedWidget(coreNode);
    }
  }

  return false;
}

const defaultProps: Partial<FeaturedPlacesWidgetProps> = {
  titleSrOnly: false,
  pageSize: 10,
  showPager: true,
  layoutProps: defaultListLayoutProps
};

export function getFinalProps(props: FeaturedPlacesWidgetProps): FeaturedPlacesWidgetProps {
  return merge(defaultProps, props, {
    undefinedMergeBehavior: UndefinedValueMergeBehavior.IGNORE_BEFORE_MERGE,
    mergeNested: false
  });
}

/**
 * Renders the FeaturedPlacesWidget, which allows users to "feature" forum posts, blog posts, and kb messages from
 * anywhere in the community
 *
 * @author Luisina Santos
 */
const FeaturedPlacesWidget: InstanceScopedWidgetFC<FeaturedPlacesWidgetProps> = props => {
  const { isVisible, ...rest } = props;
  const finalProps = getFinalProps(rest);
  const {
    instanceId, // do not add a default value here, instanceId should be assigned via page editor or a page quilt
    className,
    layoutProps,
    titleSrOnly,
    pageSize,
    showPager
  } = finalProps;

  const cx = useClassNameMapper(localStyles);
  const {
    publicConfig: { auroraFeaturedWidgetsEnabled }
  } = useContext(TenantContext);
  const quilt: QuiltFragment = useContext(QuiltContext);
  const pageEditorContextInterface: EditContextInterface = useContext(EditContext);
  const {
    contextNode: { id: nodeId }
  } = useContext(AppContext);

  const { formatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.FEATURED_PLACES_WIDGET
  );

  const { router, loading: routesLoading } = useEndUserRoutes();
  const isPageEditorPage: boolean = router.getCurrentPageName() === EndUserPages.PageEditorPage;

  const quiltId: string = isPageEditorPage ? pageEditorContextInterface?.quilt?.id : quilt?.id;

  const placesQueryVariables: FeaturedPlacesWidgetQueryVariables =
    getFeaturedPlacesWidgetQueryVariables(
      instanceId,
      pageSize,
      quiltId,
      nodeId,
      layoutProps || defaultListLayoutProps
    );

  const [showAddPlacesModal, setShowAddPlacesModal] = useState<boolean>(false);
  const [placesCount, setPlacesCount] = useState<number>(-1);
  const [placesQueryError, setPlacesQueryError] = useState<ApolloError>(null);

  /**
   * Updates the places and last modified history in component state
   *
   * @param places the places returned by the FeaturedPlacesWidget query
   * @param lastModHistory the last modified date and user for the widget
   */
  const onPlacesQueryUpdate = useCallback(
    (queryResult: QueryResult<FeaturedPlacesWidgetQuery, FeaturedPlacesWidgetQueryVariables>) => {
      const { data, error } = queryResult;
      setPlacesQueryError(error);
      if (data) {
        const {
          featuredPlacesWidget: {
            coreNodes: { totalCount }
          }
        } = data;
        setPlacesCount(totalCount);
      }
    },
    []
  );

  const {
    data: nodePoliciesQueryData,
    loading: nodePoliciesQueryLoading,
    error: nodePoliciesQueryError
  } = useNodePolicies(
    module,
    {
      canManageFeaturedWidget: true,
      canUpdateFeaturedWidget: true
    },
    !auroraFeaturedWidgetsEnabled
  );

  useEffect(() => {
    if (!auroraFeaturedWidgetsEnabled) {
      isVisible(false);
    } else if (
      !textLoading &&
      !nodePoliciesQueryLoading &&
      !isPageEditorPage &&
      placesCount !== -1
    ) {
      isVisible(
        isFeaturedPlacesWidgetVisible(isPageEditorPage, nodePoliciesQueryData, placesCount > 0)
      );
    }
  }, [
    auroraFeaturedWidgetsEnabled,
    isPageEditorPage,
    isVisible,
    placesCount,
    nodePoliciesQueryData,
    nodePoliciesQueryLoading,
    textLoading
  ]);

  /**
   * Toggles the visibility of the AddFeaturedPlacesModal
   */
  function toggleModal(): void {
    setShowAddPlacesModal(previousState => !previousState);
  }

  const title: string = formatMessage('title');

  if (nodePoliciesQueryError) {
    log.error('error checking policies for FeaturedPlacesWidget', nodePoliciesQueryError.message);
  }

  if (placesQueryError) {
    log.error(
      `error retrieving places for FeaturedPlacesWidget with instance id ${instanceId}`,
      placesQueryError.message
    );
  }

  if (
    !auroraFeaturedWidgetsEnabled ||
    (!isPageEditorPage &&
      typeof placesCount === 'number' &&
      placesCount === 0 &&
      !canUpdateFeaturedWidget(nodePoliciesQueryData?.coreNode)) ||
    textLoading ||
    routesLoading ||
    nodePoliciesQueryLoading ||
    nodePoliciesQueryError ||
    placesQueryError
  ) {
    return null;
  }

  /**
   * Renders the edit button which allows users to update the places from the enduser application
   */
  function renderEditButton(): React.ReactElement {
    return (
      <Button
        className={cx('lia-edit-btn')}
        testId="FeaturedPlacesWidget.Edit.Button"
        variant={ButtonVariant.NO_VARIANT}
        onClick={() => setShowAddPlacesModal(previousState => !previousState)}
        aria-label={formatMessage('edit')}
      >
        <Icon icon={Icons.EditIcon} size={IconSize.PX_16} color={IconColor.GRAY_900} />
      </Button>
    );
  }

  return (
    <EditableWidget<InstanceScopedWidgetProps> props={finalProps}>
      <AddFeaturedPlacesModal
        show={showAddPlacesModal}
        onHide={() => setShowAddPlacesModal(false)}
        placesQueryVariables={placesQueryVariables}
      />
      <div className={cx('position-relative')}>
        {!isPageEditorPage &&
          typeof placesCount === 'number' &&
          placesCount > 0 &&
          canUpdateFeaturedWidget(nodePoliciesQueryData.coreNode) &&
          renderEditButton()}
        <FeaturedPlacesList
          placesQueryVariables={placesQueryVariables}
          className={className}
          toggleModal={toggleModal}
          onPlacesQueryUpdate={onPlacesQueryUpdate}
          layoutProps={layoutProps}
          header={{ title, titleSrOnly }}
          showPager={showPager}
        />
      </div>
    </EditableWidget>
  );
};
export default FeaturedPlacesWidget;
