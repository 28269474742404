import { IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import { NodeCardSize } from '@aurora/shared-types/nodes/enums';
import { TextAlignment } from '@aurora/shared-types/texts/enums';
import Icons from '@aurora/shared-client/icons';

/**
 * Helper function to return an icon size based on a card size
 * @param selectedCardSize The card size to retrieve the icon size against
 */
export function getNodeIconSize(selectedCardSize: NodeCardSize): IconSize {
  switch (selectedCardSize) {
    case NodeCardSize.XS: {
      return IconSize.PX_50;
    }
    case NodeCardSize.SM: {
      return IconSize.PX_80;
    }
    default: {
      return IconSize.PX_160;
    }
  }
}

/**
 * Helper function to return allowed alignment options for the node card.
 */
export function nodeCardAlignmentOptions() {
  return [
    {
      key: TextAlignment.LEFT,
      value: TextAlignment.LEFT,
      icon: Icons.TextAlignLeftIcon
    },
    {
      key: TextAlignment.CENTER,
      value: TextAlignment.CENTER,
      icon: Icons.TextAlignCenterIcon
    },
    {
      key: TextAlignment.RIGHT,
      value: TextAlignment.RIGHT,
      icon: Icons.TextAlignRightIcon
    }
  ];
}
