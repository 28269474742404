import type { ClampLinesType } from '@aurora/shared-types/community/enums';
import type { NodeCardSize } from '@aurora/shared-types/nodes/enums';
import type { TextAlignment } from '@aurora/shared-types/texts/enums';
import type { InstanceScopedWidgetProps } from '../../../common/Widget/types';

export enum FeaturedPlacesWidgetLayout {
  LIST = 'list',
  CARD = 'card'
}

export enum FeaturedPlacesWidgetListStyle {
  SPACE = 'space',
  DIVIDE = 'divide',
  BORDER = 'border'
}

export enum LeadWithOption {
  ICON = 'icon',
  AVATAR = 'avatar',
  NONE = 'none'
}

/**
 * Node elements options available for both the List and Card layouts
 */
interface CommonNodeLayoutOptions {
  /**
   * Whether to display the node description
   */
  useNodeDescription: boolean;
  /**
   * Whether to display the latest node activity timestamp
   */
  useNodeLatestActivityTime: boolean;
  /**
   * Whether to display the node topics count
   */
  useNodeTopicsCount: boolean;
  /**
   * Whether to display the unread posts count
   */
  useUnreadMessagesCount: boolean;
}

/**
 * Node element options available for the List layout
 */
export interface ListLayoutOptions extends CommonNodeLayoutOptions {
  /**
   * Whether to display the lock icon
   */
  useLockIcon: boolean;
}

/**
 * Node element options available for the Card layout
 */
export interface CardLayoutOptions extends CommonNodeLayoutOptions {
  /**
   * Whether to display the node avatar
   */
  useNodeAvatar: boolean;
  /**
   * Whether to display the child nodes
   */
  useChildNodes: boolean;
}

/**
 * The possible options to display in a layout item
 */
export type LayoutOptions = ListLayoutOptions | CardLayoutOptions;

/**
 * Properties shared by both List layouts
 */
interface CommonLayoutProps {
  /**
   * Number of clamp lines for description
   */
  descriptionClampLines: ClampLinesType;
}

/**
 * Options for the "List" layout which can be selected by the user in Page Builder
 */
export interface ListLayoutProps extends CommonLayoutProps {
  /**
   * List styling
   */
  listStyle: FeaturedPlacesWidgetListStyle;
  /**
   * The layout type
   */
  layout: FeaturedPlacesWidgetLayout.LIST;
  /**
   * Layout options for the node unique to the List layout
   */
  layoutOptions: ListLayoutOptions;
  /**
   * An element that appears on the left-hand side of the node
   */
  leadWithOption: LeadWithOption;
  /**
   * The page size of the descendant nodes
   */
  nodeDescendantsPageSize: number;
}

/**
 * Options for the "Card" layout which can be selected by the user in Page Builder
 */
export interface CardLayoutProps extends CommonLayoutProps {
  /**
   * The layout type
   */
  layout: FeaturedPlacesWidgetLayout.CARD;
  /**
   * Layout options for the node unique to the Card layout
   */
  layoutOptions: CardLayoutOptions;
  /**
   * The size for the Card layout
   */
  cardSize: NodeCardSize;
  /**
   * The text alignment for the Card layout
   */
  textAlignment: TextAlignment;
}

/**
 * Props unique to one of the layout selections made in Page Builder
 */
export type LayoutProps = ListLayoutProps | CardLayoutProps;

/**
 * The FeaturedPlacesWidget props interface
 */
export interface FeaturedPlacesWidgetProps extends Omit<InstanceScopedWidgetProps, 'hideIfEmpty'> {
  /**
   * Layout selection props
   */
  layoutProps: LayoutProps;
  /**
   * Whether the title is viewable by screen readers only
   */
  titleSrOnly: boolean;
  /**
   * The number of results to show before pagination
   */
  pageSize: number;
  /**
   * Whether to display the pager
   */
  showPager: boolean;
  /**
   * Whether to enable lazy loading for the widget
   */
  lazyLoad: boolean;
}

/**
 * An extension of the props interface used to differentiate static from configurable props for the widget
 */
export interface FeaturedPlacesWidgetConfigurableProps
  extends Omit<FeaturedPlacesWidgetProps, 'instanceId' | 'className'> {
  /**
   * The user-selected title for the widget
   */
  title: string;
}

/**
 * The configuration form data interface for the FeaturedPlacesWidget
 */
export interface ConfigurationFormData
  extends Omit<FeaturedPlacesWidgetConfigurableProps, 'layoutProps' | 'showPager' | 'lazyLoad'> {
  /**
   * The layout selection
   */
  layout: FeaturedPlacesWidgetLayout;
  /**
   * Node element options available for the List layout
   */
  listLayoutOptions: ListLayoutOptions;
  /**
   * Node element options available for the Card layout
   */
  cardLayoutOptions: CardLayoutOptions;
  /**
   * The list style for the List layout
   */
  listStyle: FeaturedPlacesWidgetListStyle;
  /**
   * The card size for the Card layout
   */
  cardSize: NodeCardSize;
  /**
   * The card text alignment for the Card layout
   */
  cardTextAlignment: TextAlignment;
  /**
   * The element to display on the left of the node for the List layout
   */
  leadWithOption: LeadWithOption;
  /**
   * Number of clamp lines for description
   */
  descriptionClampLines: ClampLinesType;
  /**
   * The page size of the descendant nodes
   */
  nodeDescendantsPageSize: number;
  /**
   * Additional widget options
   */
  moreOptions: {
    /**
     * Whether to enable lazy loading for the widget
     */
    lazyLoad: boolean;
    /**
     * Whether to display a pager for the widget
     */
    showPager: boolean;
  };

  editContent: string;
}
